import { Directive, Renderer2, ElementRef, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import Scrollbar from 'smooth-scrollbar';

@Directive({
  selector: '[appSlimScroll]'
})
export class SlimscrollDirective implements AfterViewInit{

  @Input() scrollPercentage: number;
  @Output() currentScrollPercentage = new EventEmitter<number>();
  @Output() scrollbarUsed = new EventEmitter<Scrollbar>();

  constructor(private el: ElementRef, private renderer: Renderer2) { }
  scrollbar: Scrollbar;
  @Input() continuousScrolling: boolean = false

  ngAfterViewInit() {
    // smooth scroll
    this.scrollbar = Scrollbar.init(this.el.nativeElement,{continuousScrolling: this.continuousScrolling});
    this.scrollbarUsed.emit(this.scrollbar)
    
  }
  

}
