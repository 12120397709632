import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Home, ServiceProvider, User } from './search.model';

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private db: AngularFireDatabase, ) { }

  getUsers(start, end): Observable<User[]>{
    return this.db.list<User>('test-toast/messages', ref => 
    ref.orderByKey()
    .startAt(start)
    .endAt(end)
    .limitToFirst(5)
    ).snapshotChanges()
    .pipe(map(changes => changes
    .map(c => ({ key: c.payload.key, ...c.payload.val() }))));
  }

  getHomes(start, end): Observable<Home[]>{
    return this.db.list<Home>('test-toast/messages', ref => 
    ref.orderByKey()
    .startAt(start)
    .endAt(end)
    .limitToFirst(5)
    ).snapshotChanges()
    .pipe(map(changes => changes
    .map(c => ({ key: c.payload.key, ...c.payload.val() }))));
  }

  getServiceProviders(start, end): Observable<ServiceProvider[]>{
    return this.db.list<ServiceProvider>('test-toast/messages', ref => 
    ref.orderByKey()
    .startAt(start)
    .endAt(end)
    .limitToFirst(5)
    ).snapshotChanges()
    .pipe(map(changes => changes
    .map(c => ({ key: c.payload.key, ...c.payload.val() }))));
  }
}
