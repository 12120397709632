import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';
import { SIDEBAR_WIDTH_CONDENSED } from '../../layout.model';

@Component({
  selector: 'app-leftsidebar',
  templateUrl: './leftsidebar.component.html',
  styleUrls: ['./leftsidebar.component.scss'],

})
export class LeftsidebarComponent implements OnInit {

  @Input() sidebarType: string;

  uid: String
  username: String
  photoUrl: String
  email: String

  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  ngOnInit() {
    let user = this.authenticationService.currentUser();
    if (user){
      this.uid = user.uid
      this.username = user.displayName
      this.photoUrl = user.photoURL
      this.email = user.email
    }
    this.authenticationService.fetchDefualtCompanyAndCurrentRole().then(() =>{
      let role = this.authenticationService.userRole
      console.log("Is admin "+this.authenticationService.isAdmin(role))
      console.log("Is editor "+this.authenticationService.isEditor(role))
      console.log("Is viewer "+this.authenticationService.isViewer(role))

    });

  }

  /**
   * Is sidebar condensed?
   */
  isSidebarCondensed() {
    return this.sidebarType === SIDEBAR_WIDTH_CONDENSED;
  }

  /**
   * Logout the user
   */
  logout() {
    this.authenticationService.logout();
    this.router.navigate(['/account/login'], { queryParams: { returnUrl: '/' } });
  }
}
