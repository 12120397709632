import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { NgbDropdownModule, NgbTooltipModule, NgbCollapseModule, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { ClickOutsideModule } from 'ng-click-outside';

import { UIModule } from '../shared/ui/ui.module';
import { EventService } from '../core/services/event.service';

import { NgbToastModule } from '@ng-bootstrap/ng-bootstrap'; 
import { LayoutComponent } from './layout.component';
import { MenuComponent } from './shared/menu/menu.component';
import { TopbarComponent } from './shared/topbar/topbar.component';
import { FooterComponent } from './shared/footer/footer.component';
import { RightsidebarComponent } from './shared/rightsidebar/rightsidebar.component';
import { LeftsidebarComponent } from './shared/leftsidebar/leftsidebar.component';
import { HorizontalComponent } from './horizontal/horizontal.component';
import { VerticalComponent } from './vertical/vertical.component';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { ToastMessagesComponent } from '../toast-messages/toast-messages.component';
import { ReversePipe } from '../toast-messages/reverse.pipe';
import { ToastService } from '../toast-messages/toast-messages.service';
import { AlertComponent } from '../alert/alert.component';
import { AlertService } from '../core/services/alert.service';

@NgModule({
  // tslint:disable-next-line: max-line-length
  declarations: [
    LayoutComponent, 
    MenuComponent, 
    TopbarComponent, 
    RightsidebarComponent, 
    FooterComponent, 
    LeftsidebarComponent, 
    FooterComponent, 
    HorizontalComponent, 
    VerticalComponent,
    ToastMessagesComponent,
    AlertComponent,
    ReversePipe
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbToastModule,
    NgbDropdownModule,
    NgbTooltipModule,
    AngularSvgIconModule.forRoot(),
    NgbCollapseModule,
    ClickOutsideModule,
    UIModule,
    NgbModule,
    FormsModule
  ],
  exports: [TopbarComponent, MenuComponent, LeftsidebarComponent, FooterComponent],
  providers: [
    EventService,
    ToastService,
    AlertService
  ]
})
export class LayoutsModule { }
