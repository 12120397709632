import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Message, ToastService } from './toast-messages.service';

@Component({
  selector: 'toast-messages',
  templateUrl: './toast-messages.component.html',
  styleUrls: ['./toast-messages.component.scss']
})
export class ToastMessagesComponent implements OnInit {

  messages: any;
  credentials$: Observable<any>;
  constructor(private authService: AuthService, private toast: ToastService) { 
    
    this.credentials$ = this.authService.credentials$;
    this.credentials$.subscribe((credential) => {
      if (credential != null){
        this.getMessages()
      }
      
    })
  }

  ngOnInit() {
    
  }

  getMessages(){
    this.messages = this.toast.getMessages()
  }

  dismiss(itemKey: string) {
    console.log(itemKey);
    
    this.toast.dismissMessage(itemKey)
  }

}
