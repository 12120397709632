import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

import { auth } from  'firebase/app';
import { database } from 'firebase/app'
import { AngularFireAuth } from  "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { User } from  'firebase';
import { Roles } from '../models/auth.models'
import { from } from 'rxjs';
import { PresenceService } from 'src/app/pages/apps/chat/presence.service';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User;

    userRole: Roles = new Roles()

    defaultEstateId: string = "";
    defaultEstateName: string = "";

    persistent: boolean = false;

    constructor(public  afAuth:  AngularFireAuth, public afDatabase: AngularFireDatabase, private chatPresence: PresenceService) { 
    
        

    }

    public setPersistence(isPersistent: boolean){
        this.persistent = isPersistent;
    }

    /**
     * Returns the current user
     */
    public currentUser(): User {
        console.log("Getting current user");

        if (!this.user ){
            console.log("Getting current user from null");
            this.user = auth().currentUser
        }
        
        return this.user;
    }





    public fetchDefualtCompanyAndCurrentRole() {
    
        return this.afDatabase.object('users/'+this.user.uid+'/estates/default').query.once("value").then(snapshot =>{
            if (snapshot.val()=== null){
                return console.error("Error default company not defeined")
            }
            this.defaultEstateId = snapshot.val()
            return this.afDatabase.object('users/'+this.user.uid+'/estates/profiles/'+this.defaultEstateId).query.once("value").then(postsnapshot =>{
                if (postsnapshot.val() && postsnapshot.val().role){
                    this.userRole[postsnapshot.val().role] = true
                    this.defaultEstateName = postsnapshot.val().company
                    return console.log("Roles "+this.userRole.viewer)
                } else {
                    this.userRole = new Roles
                    return console.error("Error user role not defined");
                }
                
            }).catch (error => {
                this.userRole = new Roles
                return console.error("Failed to get user role: "+error);
            })
        }).catch (error => {
            this.userRole = new Roles
            return console.error("Failed to get default company: "+error);
        })

    }


    getDefaultEstateId(){
        return this.defaultEstateId
    }

    getDefaultEstateName(){
        return this.defaultEstateName
    }


    /**
     * Performs the auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string) {

        var firebasePersistence: string

        if (this.persistent){
            firebasePersistence = auth.Auth.Persistence.LOCAL;
        } else {
            firebasePersistence = auth.Auth.Persistence.SESSION;
        }
        return this.afAuth.setPersistence(firebasePersistence).then(() => {
            return this.afAuth.signInWithEmailAndPassword(email, password).then(mAuth => {
                console.log("Signed in", mAuth)
                this.user = mAuth.user

                const error = null
                const user = mAuth.user
                return this.fetchDefualtCompanyAndCurrentRole().then(() =>{
                    
                    return {error, user}
                }).catch(error =>{
                    console.error("Failed to get company: "+error);
                    error = "Failed to get your the company you are assigned to."
                    
                    const user = null
                    return {error, user}
                })
                
         
                
            }).catch(error =>{
                console.error("Failed to signin: "+error);
                
                this.user = null;
                
                const user = null
                return {error, user};
            })
        }).catch((error) => {
            console.error("Failed to set presistence: "+error);

            this.user = null;
                
            const user = null
            return {error, user};

        })   
    }

    getUserRoleAsString(): string {
        if (this.userRole.admin){
            return "ADMINISTRATOR"
        } else if (this.userRole.editor){
            return "EDITOR"
        } else if (this.userRole.viewer){
            return "VIEWER"
        } else {
            return "NO ROLE ASSIGNED"
        }
    }

    canRead(role: Roles): boolean {
        const allowed = ['admin', 'editor', 'viewer']
        return this.checkAuthorization(role,allowed)
    }

    canEdit(role: Roles): boolean {
        const allowed = ['admin', 'editor', 'viewer']
        return this.checkAuthorization(role,allowed)
    }

    isViewer(role: Roles): boolean {
        const allowed = ['admin', 'editor', 'viewer']
        return this.checkAuthorization(role,allowed)
    }

    isEditor(role: Roles): boolean {
        const allowed = ['admin', 'editor']
        return this.checkAuthorization(role,allowed)
    }

    isAdmin(role: Roles): boolean {
        const allowed = ['admin']
        return this.checkAuthorization(role,allowed)
    }

   
    private checkAuthorization(userRole: Roles, allowedRoles: string[]): boolean {
        if (!userRole) return false;
        for (const role of allowedRoles) {
            if ( this.userRole[role] ) {
                return true;
            }
        }
        return false;
    }

    /**
     * Logout the user
     */
    logout() {
        // remove user from local storage to log user out
        this.chatPresence.signOut()
        this.afAuth.signOut();
        this.user = null;
    }
}

