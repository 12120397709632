import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AngularFireAuth } from  "@angular/fire/auth"
import { map } from 'rxjs/operators';

import { AuthenticationService } from '../services/auth.service';
import { auth } from 'firebase';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private afAuth: AngularFireAuth,
        private authenticationService: AuthenticationService
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.afAuth.authState.pipe(map((user =>  {
            
            if(user == null) {
                // not logged in so redirect to login page with the return url
                this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
                console.log("User Null");
                return false;
            } else {
                console.log("User Exits");
                return true;
            }
        })))
    }
    
}