import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService } from '../../../core/services/auth.service';

import { Notification } from './topbar.model';

import { notificationItems, statesWithFlags } from './data';
import { Observable, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, map } from 'rxjs/operators';
import { SearchService } from './search.service';
import { Home, ServiceProvider, User } from './search.model';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {

  
  public model: any;

  USER_SEARCH_TYPE = 'user'
  HOME_SEARCH_TYPE = 'home'
  SERVICE_PROVIDERS_SEARCH_TYPE = 'service-provider'

  notificationItems: Notification[];
  languages: Array<{
    id: number,
    flag?: string,
    name: string
  }>;
  selectedLanguage: {
    id: number,
    flag?: string,
    name: string
  };

  openMobileMenu: boolean;

  @Output() settingsButtonClicked = new EventEmitter();
  @Output() mobileMenuButtonClicked = new EventEmitter();

  searchUsers: User[] = [];
  searchHomes: Home[] = [];
  searchServiceProviders: ServiceProvider[] = [];

  startAt = new Subject()
  endAt = new Subject()

  constructor(private router: Router, private authService: AuthenticationService, private searchService: SearchService) {}

  ngOnInit() {
    // get the notifications
    this._fetchNotifications();
    this.openMobileMenu = false;

    //this.searchService.getUsers(this.startAt, this.endAt)
    //                    .subscribe(users => this.searchUsers)
                        
    //this.searchService.getHomes(this.startAt, this.endAt)
    //                    .subscribe(homes => this.searchHomes)

    //this.searchService.getServiceProviders(this.startAt, this.endAt)
    //                    .subscribe(serviceProviders => this.searchServiceProviders)
  }

  /**
   * Change the language
   * @param language language
   */
  changeLanguage(language) {
    this.selectedLanguage = language;
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
    this.router.navigate(['/account/login']);
  }

  /**
   * Fetches the notification
   * Note: For now returns the hard coded notifications
   */
  _fetchNotifications() {
    this.notificationItems = notificationItems;
  }

  search: OperatorFunction<string, readonly {key, name, image, type}[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      map(term => {

        const users = this.searchUsers.map(function(user){
          return {key:user.uid, 
                  name:user.name,
                  image:user.photoUrl,
                  type: this.USER_SEARCH_TYPE
                };
        })
        const homes = this.searchHomes.map(function(home){
          return {key:home.id, 
                  name:home.unit,
                  image:null,
                  type: this.HOME_SEARCH_TYPE
                };
        })
        const serviceProviders = this.searchServiceProviders.map(function(serviceProvider){
          return {key:serviceProvider.id, 
                  name:serviceProvider.name,
                  image:serviceProvider.photoUrl,
                  type: this.SERVICE_PROVIDERS_SEARCH_TYPE
                };
        })

        this.startAt.next(term)
        this.endAt.next(term+"\uf8ff")
        //return term === '' ? [] : [ ...users, ...homes, ...serviceProviders]
        const array = statesWithFlags.filter(v => v.name.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10)
        const testArray = array.map((value, index) => {
          return {  key:index,
                    name:value.name,
                    image:value.flag,
                    type:this.USER_SEARCH_TYPE}
        })
        return term === '' ? [] : testArray
       
     })
    );

  searchItemSelected(event: NgbTypeaheadSelectItemEvent){
    console.log(event.item.key)
    console.log(event.item.name)
    switch(event.item.type) { 
      case this.USER_SEARCH_TYPE: { 
        this.router.navigate(['/user-profile/:'+event.item.key]);
        break; 
      } 
      case this.HOME_SEARCH_TYPE: { 
         //statements; 
         break; 
      } 
      case this.SERVICE_PROVIDERS_SEARCH_TYPE: { 
        //statements; 
        break; 
     }
      default: { 
         break; 
      } 
    }
  }

  formatter = (x: {name: string}) => x.name;
}
