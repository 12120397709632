import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';

export enum AlertType {
    Dismissible = "dismissible",
    Timeout = "timeout",
    Persistent = "persistent"
}

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterRouteChange = false;

    constructor(private router: Router) {
        // clear alert messages on route change unless 'keepAfterRouteChange' flag is true
        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterRouteChange) {
                    // only keep for a single route change
                    this.keepAfterRouteChange = false;
                } else {
                    // clear alert message
                    this.clear();
                }
            }
        });
    }

    getAlert(): Observable<any> {
        return this.subject.asObservable();
    }

    success(message: string, alertType: AlertType = AlertType.Persistent, timeout: number = 3000, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ class: 'success', text: message, alertType: alertType, timeout: timeout });
    }

    warning(message: string, alertType: AlertType = AlertType.Persistent, timeout: number = 3000, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ class: 'warning', text: message, alertType: alertType, timeout: timeout });
    }

    error(message: string, alertType: AlertType = AlertType.Persistent, timeout: number = 3000, keepAfterRouteChange = false) {
        this.keepAfterRouteChange = keepAfterRouteChange;
        this.subject.next({ class: 'error', text: message, alertType: alertType, timeout: timeout });
    }

    clear() {
        // clear by calling subject.next() without parameters
        this.subject.next();
    }
}