import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { BehaviorSubject, Observable } from 'rxjs';

export interface Credentials{
  token: string;
  userId: string;

}


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private _credentials: BehaviorSubject<Credentials> = new BehaviorSubject<Credentials>(null);
  public readonly credentials$: Observable<Credentials> = this._credentials.asObservable();
  
  private _loggedIn: boolean = false

  db: AngularFireDatabase

  // Todo finish this 
  constructor(private afAuth: AngularFireAuth, db: AngularFireDatabase) { 

    this.db = db

    this.afAuth.authState.subscribe(user => {
      this._credentials.next(null);
      if (user) {
        user.getIdTokenResult().then(data => {
          const credentials = {
            token: data.token,
            userId: data.claims.user_id
          }
          this._credentials.next(credentials);
          this._loggedIn = true
        })
      } else {
        this._credentials.next({token: null, userId: null});
        this._loggedIn = false
      }
    })
  }

  


  public isLoggedIn(): boolean {
    return this._loggedIn
  }
}
