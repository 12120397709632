export class Roles {
    viewer?: boolean;
    editor?: boolean;
    admin?: boolean;

    constructor() {
        this.viewer = false;
        this.editor = false;
        this.admin = false;
    }
}

