import { Component, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';
import { AlertService, AlertType } from 'src/app/core/services/alert.service';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  private alertServiceSubscription: Subscription;

  private onlineSubscription: Subscription;
  private offlineSubscription: Subscription;

  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;

  connectedToInternet: Boolean = true;
  message: any;
  dismissible: boolean = false

  constructor(private alertService: AlertService) { }

  ngOnInit() {

    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.alertServiceSubscription = this.alertService.getAlert()
      .subscribe(message => {
        console.log("Alert: ",message);
        
        switch (message && message.class) {
          case 'success':
            message.cssClass = 'success';
            break;
          case 'warning':
            message.cssClass = 'warning';
            break;
          case 'error':
            message.cssClass = 'danger';
            break;
        }

          this.message = message;

          if (message) {
            
            this.dismissible = message.alertType == AlertType.Dismissible ? true: false
            
            if (message.alertType === AlertType.Timeout) {
              setTimeout(() => {
                this.alertService.clear()
              },message.timeout)
            }
          }
          
      });


      this.onlineSubscription = this.onlineEvent.subscribe(e => {
        this.connectedToInternet = true;
      })

      this.offlineSubscription = this.offlineEvent.subscribe(e => {
        this.connectedToInternet = false;
      })
  }

  ngOnDestroy() {
    this.alertServiceSubscription.unsubscribe();
    this.onlineSubscription.unsubscribe();
    this.offlineSubscription.unsubscribe();
  }

}
