import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class Message {
    title: string;
    content: string;
    photoUri: string;
    dismissed: boolean = false;
  
    constructor(title: string, content: string, photoUri:string) {
        this.title = title
        this.content = content
        this.photoUri = photoUri
    }
  
}

@Injectable()
export class ToastService {


  constructor(private db: AngularFireDatabase) { }

  getMessages(): Observable<Message[]> {
    
    return this.db.list<Message>('test-toast/messages', ref => ref.orderByKey().limitToFirst(5)).snapshotChanges()
    .pipe(map(changes => changes
    .map(c => ({ key: c.payload.key, ...c.payload.val() }))));
  }

  sendMessage(title: string, content: string, photoUri: string) {
    const message = new Message(title, content, photoUri)
    this.db.list('test-toast/messages').push(message)
  }

  dismissMessage(messageKey:string) {
    this.db.object(`test-toast/messages/${messageKey}`).update({'dismissed': true})
    this.db.object(`test-toast/messages/${messageKey}`).remove()
  }

}