import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import * as firebase from 'firebase/app';
import { tap, map, switchMap, first } from 'rxjs/operators';
import { TouchSequence } from 'selenium-webdriver';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresenceService {

  constructor(private afAuth: AngularFireAuth, private db: AngularFireDatabase) {
    console.log('let there be presence');
    this.updateOnUser().subscribe();
    this.updateOnDisconnect().subscribe();
  }

  
  getPresence(uid: string) {
    return this.db.object<boolean>(`test/users/${uid}/online`).valueChanges();
  }

  getUser() {
    return this.afAuth.authState.pipe(first()).toPromise();
  }


 async setPresence(status: boolean) {
    const user = await this.getUser();
    if (user) {
      return this.db.object(`test/users/${user.uid}/online`).set(status);
    }
  }

  get timestamp() {
    return firebase.database.ServerValue.TIMESTAMP;
  }

  updateOnUser() {
    const connection = this.db.object('.info/connected').valueChanges().pipe(
      map(connected => connected ? true : false)
    );

    return this.afAuth.authState.pipe(
      switchMap(user =>  user ? connection : of(true)),
      tap(status => this.setPresence(status))
    );
  }

  updateOnDisconnect() {
    return this.afAuth.authState.pipe(
      tap(user => {
        if (user) {
          this.db.object(`test/users/${user.uid}/online`).query.ref.onDisconnect()
            .set(false);
        }
      })
    );
  }

  async signOut() {
    await this.setPresence(false);
}

}